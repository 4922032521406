import { useCallback, useEffect, useState } from "react";
import useApi from "./useApi";
import {
  CompletionContext,
  CompletionResult,
  Completion,
} from "@codemirror/autocomplete";
import { Diagnostic, LintSource } from "@codemirror/lint";
import { EditorView } from "@uiw/react-codemirror";
import { BASE_URL } from "constants/common.constants";

// Static URLs
const SCREENS_URL = `${BASE_URL}/interviews/api/get_screens/`;
const VARIABLES_URL = `${BASE_URL}/interviews/api/get_variables/`;
const ALL_VARIABLES_URL = `${BASE_URL}/interviews/api/get_all_variables/`;

const useCodeMirrorConfig = (interviewId: string, screenName?: string) => {
  // Call useApi for all possible data sources
  const {
    data: screensData,
    isLoading: screensLoading,
    error: screensError,
    fetchData: fetchScreens,
  } = useApi<any>({
    url: SCREENS_URL,
    method: "POST",
    data: { interview_type_id: interviewId },
  });

  const {
    data: allVariablesData,
    isLoading: allVariablesLoading,
    error: allVariablesError,
    fetchData: fetchAllVariables,
  } = useApi<any>({
    url: ALL_VARIABLES_URL,
    method: "POST",
    data: { interview_type_id: interviewId },
  });

  const {
    data: partiesData,
    isLoading: partiesLoading,
    error: partiesError,
    fetchData: fetchParties,
  } = useApi<any>({
    url: `${BASE_URL}/interviews/api/interviewtypes/${interviewId}/roles/`,
    method: "GET",
  });

  // Combine loading and error states
  const isLoading = screensLoading || allVariablesLoading || partiesLoading;
  const error = screensError || allVariablesError || partiesError;

  const [combinedData, setCombinedData] = useState<any>({
    screens: [],
    all_variables: [],
    parties: [],
  });

  useEffect(() => {
    if (interviewId) {
      fetchScreens();
      fetchAllVariables();
      fetchParties();
    }
  }, [interviewId, fetchScreens, fetchAllVariables, fetchParties]);

  useEffect(() => {
    if (screensData || allVariablesData || partiesData) {
      setCombinedData({
        screens: screensData?.screens || [],
        all_variables: allVariablesData?.all_variables || [],
        // use just the role_name
        parties:
          partiesData?.map((party: { role_name: any }) => party.role_name) ||
          [],
      });
    }
  }, [screensData, allVariablesData, partiesData]);

  const getHints = useCallback(
    async (context: CompletionContext): Promise<CompletionResult | null> => {
      const cursor = context.pos;
      const line = context.state.doc.lineAt(cursor);
      let start = cursor,
        end = cursor;

      // Adjust start and end to find the word around the cursor
      while (start > line.from && /\S/.test(line.text[start - line.from - 1])) {
        start--;
      }
      while (end < line.to && /\S/.test(line.text[end - line.from])) {
        end++;
      }

      const word = line.text
        .slice(start - line.from, end - line.from)
        .toLowerCase();

      let combinedList: Completion[] = [];

      if (combinedData.screens) {
        combinedList = [
          ...combinedList,
          ...combinedData.screens.map((s: any) => ({
            label: s.screen_name,
            detail: "Screen",
          })),
        ];
      }
      if (combinedData.variables) {
        combinedList = [
          ...combinedList,
          ...combinedData.variables.map((v: any) => ({
            label: v.variable_name,
            detail: "Variable",
          })),
        ];
      }
      if (combinedData.all_variables) {
        combinedList = [
          ...combinedList,
          ...combinedData.all_variables.map((v: any) => ({
            label: v.variable_name,
            detail: "Variable",
          })),
        ];
      }
      if (combinedData.parties) {
        combinedList = [
          ...combinedList,
          ...combinedData.parties.map((v: any) => ({
            label: v,
            detail: "Party",
          })),
        ];
      }

      combinedList.sort((a: any, b: any) => a.label.localeCompare(b.label));

      const found = combinedList.filter((item: any) =>
        item.label.toLowerCase().startsWith(word)
      );

      if (found.length === 0) return null;

      return {
        from: start,
        to: end,
        options: found,
      };
    },
    [combinedData]
  );

  const testValidator: LintSource = useCallback(
    (view: EditorView): Diagnostic[] => {
      const doc = view.state.doc;
      const code = doc.toString();

      // Get the extended lists for validation
      const lexerErrors = lintLogics(
        code,
        combinedData.all_variables,
        combinedData.screens,
        combinedData.parties,
        screenName
      );

      const errors: Diagnostic[] = lexerErrors.map((lexerError: any) => {
        const { message, startPosition, endPosition } = lexerError;

        const from = Math.min(startPosition, doc.length) || 0;
        const to = Math.min(endPosition, doc.length) || 0;
        const severity = "warning";
        console.log(from, to);

        return {
          from,
          to,
          message,
          severity,
        };
      });
      console.log(errors);

      return errors;
    },
    [combinedData]
  );

  return { isLoading, error, getHints, testValidator };
};

export default useCodeMirrorConfig;
