import "./RightSidebarScreenDesign.css";

import { ChangeEvent, useEffect, useState } from "react";
import CodeMirror from "@uiw/react-codemirror";
import { autocompletion } from "@codemirror/autocomplete";
import { linter, lintGutter } from "@codemirror/lint";
import axios from "axios";
import {
  Cell,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";

import { FormInput } from "components/FormInput";
import { RadioButton } from "components/RadioButton";
import { SearchBox } from "components/SearchBox";
import { Divider } from "components/Divider";
import { Button } from "components/Button";
import { DraggableModal } from "components/DraggableModal";
import { Textbox } from "components/Textbox";

import SaveSVG from "icons/right-sidebar-save.svg";
import DeleteSVG from "icons/right-sidebar-delete.svg";
import AddOptionSVG from "icons/question-design-addOption.svg";
import DeleteOptionSVG from "icons/question-design-deleteOption.svg";
import ExpandSVG from "icons/expand.svg";
import CancelSVG from "icons/cancel-dark.svg";
import MinimizeSVG from "icons/right-sidebar-minimize.svg";
import TextVarSVG from "icons/screen-design-page-textVar.svg";

import { Question } from "models/question.models";

import { getVariableTypeImage } from "helpers/common.helpers";

import useCodeMirrorConfig from "hooks/useCodeMirrorConfig";
import { BASE_URL } from "constants/common.constants";

interface Props {
  variable: any;
  screenId: string | undefined;
  interviewId: string;
  onEditQuestion: (question: any) => void;
}

export const RightSidebarScreenDesign = ({
  variable,
  screenId,
  interviewId,
  onEditQuestion,
}: Props): JSX.Element => {
  const [selectedNavOption, setselectedNavOption] = useState("Properties");
  const handleNavOptionClick = (option: any) => {
    setselectedNavOption(option);
  };

  const [question, setQuestion] = useState<Question>(() => ({
    id: "",
    variable_name: "",
    variable_type: "",
    label: "",
    choices: "",
    instructionLogic: "",
  }));

  useEffect(() => {
    setQuestion(variable?.original || "");
  }, [variable]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setQuestion((prevQuestion) => ({
      ...prevQuestion,
      [name]: value,
    }));
  };

  const handleLogicChange = (val: any) => {
    setQuestion((prevQuestion) => ({
      ...prevQuestion,
      instructionLogic: val,
    }));
  };

  const handleQuestionSubmit = (event: any) => {
    event.preventDefault();
    axios
      .patch(
        `${BASE_URL}/interviews/api/screens/${screenId}/questions/${question.id}/`,
        question
      )
      .then((response) => {
        console.log(response.data);
        onEditQuestion(response.data);
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
      });
  };

  return (
    <div className="screen-design-right-side-bar">
      <div className="sidebar-top-area">
        <img className="minimize-icon" src={MinimizeSVG} alt="Minimize Icon" />
        <SearchBox length="long" />
      </div>
      <Divider />
      <div className="variable-options-container">
        <img src={getVariableTypeImage(question.variable_type) || TextVarSVG} />
        <div className="variable-name-container">
          <div className="variable-name">
            {question.variable_name || "Variable Name"}
          </div>
        </div>
        <div className="variable-options">
          <img
            className="variable-options-image"
            src={SaveSVG}
            alt="Save Icon"
            onClick={handleQuestionSubmit}
          />
          <img className="variable-options-image" src={DeleteSVG} alt="" />
        </div>
      </div>
      <Divider />
      <div className="navigation-bar">
        <NavButton
          name="Properties"
          selected={selectedNavOption === "Properties"}
          onClick={() => handleNavOptionClick("Properties")}
        />
        <NavButton
          name="Additional Info"
          selected={selectedNavOption === "AdditionalInfo"}
          onClick={() => handleNavOptionClick("AdditionalInfo")}
        />
      </div>
      {selectedNavOption === "Properties" && (
        <VariableBasicInfo
          variable_name={question.variable_name}
          label={question.label}
          handleChange={handleInputChange}
        />
      )}
      {selectedNavOption === "Properties" &&
        question.variable_type === "multiples_choices" && (
          <SelectVariableInfo
            choices={question.choices || ""}
            handleChange={handleInputChange}
          />
        )}
      {selectedNavOption === "Properties" &&
        question.variable_type === "instruction" && (
          <InstructionVariableInfo
            logics={question.instructionLogic || ""}
            handleChange={handleLogicChange}
            interviewId={interviewId}
          />
        )}
    </div>
  );
};

interface NavButtonProps {
  name: string;
  selected?: boolean;
  onClick?: any;
}

const NavButton = ({
  name,
  selected = false,
  onClick,
}: NavButtonProps): JSX.Element => {
  const buttonClassName = `nav-button ${selected ? "nav-button-selected" : ""}`;
  return (
    <div className={buttonClassName} role="button" onClick={onClick}>
      <div className="nav-button-text">{name}</div>
    </div>
  );
};

interface VariableBasicInfoProps {
  variable_name: string;
  label: string;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const VariableBasicInfo = ({
  variable_name,
  label,
  handleChange,
}: VariableBasicInfoProps): JSX.Element => {
  return (
    <div className="variable-basic-info">
      <FormInput
        value={variable_name}
        name="variable_name"
        label="Name"
        required={true}
        onChange={handleChange}
      />
      <FormInput
        value={label}
        name={"label"}
        label="Label"
        required={true}
        onChange={handleChange}
      />
      <div className="mandatory-field-container">
        <div className="field-container">
          <div className="checkbox-container">
            <div className="checkbox"></div>
            <div className="checkbox-text">Mandatory Field?</div>
          </div>
        </div>
        <div className="field-description-container">
          <div className="field-description-text">
            Select type - Select the style of Multi Choice Field?
          </div>
        </div>
      </div>
    </div>
  );
};

interface SelectVariableInfoProps {
  choices: string;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const SelectVariableInfo = ({
  choices,
  handleChange,
}: SelectVariableInfoProps): JSX.Element => {
  interface Data {
    id: string;
    value: string;
    label: string;
  }

  const initialOptions: Data[] = choices.split(",").map((choice, index) => ({
    id: index.toString(),
    value: choice.trim(),
    label: choice.trim(),
  }));

  const [options, setOptions] = useState<Data[]>(initialOptions);

  useEffect(() => {
    const updatedOptions: Data[] = choices.split(",").map((choice, index) => ({
      id: index.toString(),
      value: choice.trim(),
      label: choice.trim(),
    }));
    setOptions(updatedOptions);
  }, [choices]);

  const addOption = () => {
    setOptions((prevOptions) => [
      ...prevOptions,
      { id: prevOptions.length.toString(), value: "", label: "" },
    ]);
  };

  const updateOptionValue = (id: string, value: string) => {
    setOptions((prevOptions) => {
      const updatedOptions = prevOptions.map((option) =>
        option.id === id ? { ...option, value: value.trim() } : option
      );
      const updatedOptionsString = updatedOptions
        .map((option) => option.value)
        .filter((value) => value !== "")
        .join(",");
      const event = {
        target: {
          name: "choices",
          value: updatedOptionsString,
        },
      } as React.ChangeEvent<HTMLInputElement>;
      handleChange(event);
      return updatedOptions;
    });
  };

  const deleteSelectedOptions = (selectedRows: Record<string, boolean>) => {
    setOptions((prevOptions) => {
      const updatedOptions = prevOptions.filter(
        (option) => !selectedRows[option.id]
      );
      const updatedOptionsString = updatedOptions
        .map((option) => option.value)
        .join(",");
      const event = {
        target: {
          name: "choices",
          value: updatedOptionsString,
        },
      } as React.ChangeEvent<HTMLInputElement>;
      handleChange(event);
      return updatedOptions;
    });
  };

  return (
    <div className="select-variable-info">
      <div className="select-header-container">
        <div className="select-header-text">Dropdown Values</div>
      </div>
      <div className="select-contents-container">
        <OptionsTable
          data={options}
          addOption={addOption}
          updateOptionValue={updateOptionValue}
          deleteSelectedOptions={deleteSelectedOptions}
        />
        <div className="select-type-container">
          <div className="select-type">
            <RadioButton
              name={"Multi Select"}
              value={"Multi"}
              checked={false}
              onChange={function (event: ChangeEvent<HTMLInputElement>): void {
                throw new Error("Function not implemented.");
              }}
              label={"Multi Select"}
            />
            <RadioButton
              name={""}
              value={""}
              checked={false}
              onChange={function (event: ChangeEvent<HTMLInputElement>): void {
                throw new Error("Function not implemented.");
              }}
              label={"Single Select"}
            />
          </div>
          <div className="select-description-container">
            <div className="select-description">
              Select type - Multi Choice or Single Choice?
            </div>
          </div>
        </div>
        <div className="select-type-container">
          <div className="select-type">
            <RadioButton
              name={"Multi Select"}
              value={"Multi"}
              checked={false}
              onChange={function (event: ChangeEvent<HTMLInputElement>): void {
                throw new Error("Function not implemented.");
              }}
              label={"Button Style"}
            />
            <RadioButton
              name={""}
              value={""}
              checked={false}
              onChange={function (event: ChangeEvent<HTMLInputElement>): void {
                throw new Error("Function not implemented.");
              }}
              label={"Checkbox"}
            />
          </div>
          <div className="select-description-container">
            <div className="select-description">
              Select type - Select the style of Multi Choice Field?
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface OptionsTableProps {
  data: any;
  addOption: () => void;
  updateOptionValue: (id: string, value: string) => void;
  deleteSelectedOptions: (selectedRows: Record<string, boolean>) => void;
}

const OptionsTable = ({
  data,
  addOption,
  deleteSelectedOptions,
  updateOptionValue,
}: OptionsTableProps) => {
  interface Data {
    id: string;
    value: string;
    label: string;
  }
  const [selectedRows, setSelectedRows] = useState<Record<string, boolean>>({});

  const TableCell = ({ getValue, row, column, table }: any) => {
    const initialValue = getValue();
    const [value, setValue] = useState(initialValue);
    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);
    const onBlur = () => {
      table.options.meta?.updateData(row.index, column.id, value);
    };
    return (
      <input
        className="td-input"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onBlur={onBlur}
      />
    );
  };

  const columns: ColumnDef<Data>[] = [
    {
      id: "select",
      header: ({ table }) => (
        <input
          type="checkbox"
          {...{
            checked: table.getIsAllRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
      cell: ({ row }) => (
        <input
          type="checkbox"
          {...{
            checked: row.getIsSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      ),
      size: 24,
    },
    { accessorKey: "value", header: "Option Value", cell: TableCell },
    {
      accessorKey: "label",
      header: ({ table }) => (
        <div className="th-label-container">
          <div className="th-label">Label</div>
          <div className="th-label-icons">
            <img src={AddOptionSVG} alt="" onClick={addOption} />
            <img
              src={DeleteOptionSVG}
              alt=""
              onClick={() => deleteSelectedOptions(selectedRows)}
            />
          </div>
        </div>
      ),
      cell: TableCell,
    },
  ];

  const table = useReactTable({
    data: data,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    state: { rowSelection: selectedRows },
    onRowSelectionChange: setSelectedRows,
    enableRowSelection: true,
    meta: {
      updateData: (rowIndex: number, columnId: string, value: string) => {
        const id = data[rowIndex].id;
        updateOptionValue(id, value);
      },
    },
  });

  return (
    <div className="options-table-container">
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  style={{
                    width: header.getSize(),
                  }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                  {header.id === "select" && (
                    <div
                      {...{
                        onDoubleClick: () => header.column.resetSize(),
                        onMouseDown: header.getResizeHandler(),
                        onTouchStart: header.getResizeHandler(),
                        className: `resizer ${
                          table.options.columnResizeDirection
                        } ${header.column.getIsResizing() ? "isResizing" : ""}`,
                      }}
                    />
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              className={row.getIsSelected() ? "selected-row" : ""}
            >
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} style={{ width: cell.column.getSize() }}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

interface InstructionVariableInfoProps {
  logics: string;
  handleChange: (value: string) => void;
  interviewId: string;
}

const InstructionVariableInfo = ({
  logics,
  handleChange,
  interviewId,
}: InstructionVariableInfoProps): JSX.Element => {
  // Modal related
  const [modalState, setModalState] = useState({
    addInstructionLogicModal: false,
  });

  const openModal = (modalName: string) =>
    setModalState({ ...modalState, [modalName]: true });
  const closeModal = (modalName: string) => {
    setModalState({ ...modalState, [modalName]: false });
  };

  const { isLoading, error, getHints, testValidator } =
    useCodeMirrorConfig(interviewId);

  return (
    <>
      <div className="instruction-expand-button-wrapper">
        <Button
          buttonName="Instructions - Expand to Write Logics"
          rightButtonImage={ExpandSVG}
          onClick={() => openModal("addInstructionLogicModal")}
        ></Button>
      </div>
      <DraggableModal
        isOpen={modalState.addInstructionLogicModal}
        onRequestClose={() => closeModal("addInstructionLogicModal")}
        handle={`.logics-modal-header`}
      >
        <div className="logics-modal">
          <div className="logics-modal-header">
            <div className="header-contents">
              <Textbox text={"Instructions - Logics"} />
              <Textbox text={"Write logics for your instruction variable"} />
            </div>
            <div className="header-close-button">
              <img
                onClick={() => closeModal("addInstructionLogicModal")}
                src={CancelSVG}
              />
            </div>
          </div>

          <div className="logics-modal-contents">
            <CodeMirror
              height="414px"
              value={logics}
              onChange={handleChange}
              extensions={[
                autocompletion({
                  activateOnTyping: false,
                  override: [getHints],
                }),
                linter(testValidator),
                lintGutter(),
              ]}
            />
          </div>
        </div>
      </DraggableModal>
    </>
  );
};
