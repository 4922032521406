import "./RightSidebarPartiesList.css";

import AddSVG from "icons/add.svg";
import EclipseSVG from "icons/right-sidebar-eclipse.svg";
import SaveSVG from "icons/right-sidebar-save.svg";
import EditSVG from "icons/right-sidebar-edit.svg";
import DeleteSVG from "icons/right-sidebar-delete.svg";
import CancelSVG from "icons/right-sidebar-cancel.svg";
import { FormSelect } from "components/FormSelect";
import { useEffect, useState } from "react";
import axios from "axios";
import { Button } from "components/Button";
import { DraggableModal } from "components/DraggableModal";
import { Textbox } from "components/Textbox";
import { FormLabel } from "components/FormLabel";
import { FormInput } from "components/FormInput";
import { RadioButton } from "components/RadioButton";
import { Party, CreateParty, Option } from "models/party.models";
import { useMutation, useQuery } from "@tanstack/react-query";
import { PartyService } from "services/PartyService";
import { validatePartyCreationData } from "validation/PartyValidation";
import { BASE_URL } from "constants/common.constants";

const filterOptions: Option[] = [
  { value: "specific_contact_type", label: "Filter by Contact Type" },
  { value: "all_values", label: "Show All Values" },
];

interface Props {
  interviewId: any;
}

export const RightSidebarPartiesList = ({
  interviewId,
}: Props): JSX.Element => {
  const [partyValues, setPartyValues] = useState<{ [key: string]: Party }>({});

  const {
    data: parties,
    isPending,
    isError,
    error,
  } = useQuery({
    queryKey: ["parties", interviewId],
    queryFn: () => PartyService.getParties(interviewId),
  });

  useEffect(() => {
    if (parties) {
      const initialPartyValues = parties.reduce((acc: any, party: Party) => {
        acc[party.id] = {
          ...party,
          role_name: party.role_name,
          filter_type: party.filter_type,
        };
        return acc;
      }, {} as { [key: number]: Party });

      setPartyValues(initialPartyValues);
    }
  }, [parties]);

  const [roleOptions, setRoleOptions] = useState<Option[]>([]);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/contacts/api/contact_types/`)
      .then((response) => {
        const fetchedOptions: Option[] = response.data.map((item: any) => ({
          value: item.contact_type,
          label: item.contact_type,
        }));
        setRoleOptions(fetchedOptions);
        console.log(fetchedOptions);
      })
      .catch((error) => {
        console.error("Failed to fetch contact types", error);
      });
  }, []);

  const handleInputChange = (
    partyId: string,
    field: keyof Party,
    value: string
  ) => {
    setPartyValues((prevValues) => ({
      ...prevValues,
      [partyId]: {
        ...prevValues[partyId],
        [field]: value,
      },
    }));
  };

  const handleSaveParty = (partyId: string) => {
    const partyData = partyValues[partyId];
    axios
      .put(
        `${BASE_URL}/interviews/api/interviewtypes/${interviewId}/roles/${partyId}/`,
        partyData
      )
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error saving role:", error);
      });
  };

  const handleDeleteParty = (partyId: string) => {
    axios
      .delete(
        `${BASE_URL}/interviews/api/interviewtypes/${interviewId}/roles/${partyId}/`
      )
      .then((response) => {
        setPartyValues((prevValues) => {
          const newValues = { ...prevValues };
          delete newValues[partyId];
          return newValues;
        });
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error deleting party:", error);
      });
  };

  // Modal related
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});

  const [modalState, setModalState] = useState({
    addPartyModal: false,
  });

  const openModal = (modalName: string) =>
    setModalState({ ...modalState, [modalName]: true });
  const closeModal = (modalName: string) => {
    setModalState({ ...modalState, [modalName]: false });
    setCreatePartyFormData({ role_name: "", filter_type: "" });
    setFormErrors({});
  };

  const [createPartyFormData, setCreatePartyFormData] = useState<CreateParty>({
    role_name: "",
    filter_type: "",
  });

  const handleModalRadioChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCreatePartyFormData({
      ...createPartyFormData,
      filter_type: event.target.value,
    });
  };

  const handleModalSelectChange = (
    selectedOption: { value: string; label: string } | null
  ) => {
    setCreatePartyFormData({
      ...createPartyFormData,
      role_name: selectedOption ? selectedOption.value : "",
    });
  };

  const {
    mutate: createParty,
    isPending: isPendingModal,
    isError: isErrorModal,
    error: errorModal,
  } = useMutation({
    mutationFn: ({
      interviewId,
      data,
    }: {
      interviewId: string;
      data: CreateParty;
    }) => PartyService.createParty(interviewId, data),
    onSuccess: (responseData) => {
      setPartyValues((prevValues) => {
        const party: Party = responseData;
        prevValues[party.id] = {
          ...party,
          role_name: party.role_name,
          filter_type: party.filter_type,
        };
        const newValues = { ...prevValues };
        return newValues;
      });

      closeModal("addPartyModal");
    },
    onError: (error) => {
      console.error("Error creating screen:", error);
    },
  });

  const handleModalSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const validationErrors = validatePartyCreationData(createPartyFormData);
    if (Object.keys(validationErrors).length > 0) {
      setFormErrors(validationErrors);
      return;
    }

    setFormErrors({});
    createParty({ interviewId, data: createPartyFormData });
  };

  return (
    <div className="parties-container">
      <a
        href="#"
        className="party-add-button"
        onClick={() => openModal("addPartyModal")}
      >
        <img className="party-add-icon" src={AddSVG} />
        <div className="party-add-text">Add New Party</div>
      </a>
      <div className="parties-list">
        {Object.keys(partyValues).map((partyId, index) => (
          <PartyItem
            key={partyId}
            party={partyValues[partyId]}
            index={index}
            filterOptions={filterOptions}
            roleOptions={roleOptions}
            onInputChange={handleInputChange}
            onSave={handleSaveParty}
            onDelete={handleDeleteParty}
          />
        ))}
      </div>
      <DraggableModal
        isOpen={modalState.addPartyModal}
        onRequestClose={() => closeModal("addPartyModal")}
        handle={`.party-modal-header`}
      >
        <div className="party-modal">
          <div className="party-modal-header">
            <div className="header-contents">
              <Textbox text={"Add New Party"} />
              <Textbox text={createPartyFormData.role_name || "Party Name"} />
            </div>
            <div className="header-close-button">
              <img
                onClick={() => closeModal("addPartyModal")}
                src={CancelSVG}
              />
            </div>
          </div>

          <div className="party-modal-contents">
            <div className="party-modal-fields">
              <FormLabel>Party Name</FormLabel>
              <FormSelect
                name="role_name"
                options={roleOptions}
                onChange={handleModalSelectChange}
                placeholder="Select party name"
              />
              {formErrors.role_name && (
                <div className="radio-button-error">{formErrors.role_name}</div>
              )}
            </div>

            <div className="party-type-details">
              <div className="party-type-options">
                <RadioButton
                  name="filter_type"
                  value="specific_contact_type"
                  checked={
                    createPartyFormData.filter_type === "specific_contact_type"
                  }
                  onChange={handleModalRadioChange}
                  label="Filter by Contact Type"
                />
                <RadioButton
                  name="filter_type"
                  value="all_values"
                  checked={createPartyFormData.filter_type === "all_values"}
                  onChange={handleModalRadioChange}
                  label="Show All Values"
                />
              </div>
              <div className="party-type-description">
                <div className="party-type-description-text">Filter type</div>
              </div>
              {formErrors.filter_type && (
                <div className="radio-button-error">
                  {formErrors.filter_type}
                </div>
              )}
            </div>
          </div>

          <div className="party-modal-actions">
            <Button
              buttonName={"Save & Continue Interview Design"}
              buttonImage={SaveSVG}
              onClick={handleModalSubmit}
            />
            <Button buttonName={"Save & Add More"} buttonImage={SaveSVG} />
            <Button
              onClick={() => closeModal("addPartyModal")}
              buttonName={"Cancel"}
              buttonImage={CancelSVG}
            />
          </div>
        </div>
      </DraggableModal>
    </div>
  );
};

const PartyItem = ({
  party,
  index,
  roleOptions,
  onInputChange,
  onSave,
  onDelete,
}: {
  party: Party;
  index: number;
  roleOptions: Option[];
  filterOptions: Option[];
  onInputChange: (partyId: string, field: keyof Party, value: string) => void;
  onSave: (partyId: string) => void;
  onDelete: (partyId: string) => void;
}) => (
  <div className="party-item" key={party.id}>
    <div className="party-item-container">
      <div className="party-number-container">
        <img
          className="party-number-eclipse"
          src={EclipseSVG}
          alt="Eclipse Icon"
        />
        <div className="party-number-box">
          <div className="party-number">{index + 1}</div>
        </div>
      </div>
      <div className="party-details-container">
        <FormSelect
          options={roleOptions}
          value={
            roleOptions.find((option) => option.value === party.role_name) ||
            null
          }
          onChange={(selectedOption: any) =>
            onInputChange(party.id, "role_name", selectedOption.value)
          }
          placeholder="Select role name"
        />
        <FormSelect
          options={filterOptions}
          value={
            filterOptions.find(
              (option) => option.value === party.filter_type
            ) || null
          }
          onChange={(selectedOption: any) =>
            onInputChange(party.id, "filter_type", selectedOption.value)
          }
          placeholder="Select filter type"
        />
      </div>
      <div className="party-actions-container">
        <img
          onClick={() => onSave(party.id)}
          className="party-action-icon"
          src={SaveSVG}
          alt="Save icon"
          role="button"
        />
        <img className="party-action-icon" src={EditSVG} alt="Edit icon" />
        <img
          onClick={() => onDelete(party.id)}
          className="party-action-icon"
          src={DeleteSVG}
          alt="Delete icon"
        />
      </div>
    </div>
  </div>
);
