import axios from "axios";
import { BASE_URL } from "constants/common.constants";
import { CreateParty } from "models/party.models";

export const PartyService = {
  getParties: async (interviewId: string): Promise<any> => {
    const response = await axios.get(
      `${BASE_URL}/interviews/api/interviewtypes/${interviewId}/roles/`
    );
    return response.data;
  },

  createParty: async (interviewId: string, data: CreateParty): Promise<any> => {
    const response = await axios.post(
      `${BASE_URL}/interviews/api/interviewtypes/${interviewId}/roles/`,
      data
    );
    return response.data;
  },
};
